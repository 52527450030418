import validate from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/middleware/authenticated.js"),
  "check-token": () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/middleware/checkToken.js"),
  guest: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/middleware/guest.js")
}