import { default as indexdRokSwOrpoMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/account/index.vue?macro=true";
import { default as manageYMlnSb7RlLMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/account/manage.vue?macro=true";
import { default as securityLIvReDfQ4kMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/account/security.vue?macro=true";
import { default as createM07o6y4v5eMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/aml-check/buyer/create.vue?macro=true";
import { default as index8hU88Q7le0Meta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/aml-check/buyer/index.vue?macro=true";
import { default as createpJDfbvD67YMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/aml-check/seller/create.vue?macro=true";
import { default as indexmqp6Sv48xKMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/aml-check/seller/index.vue?macro=true";
import { default as _91id_9376Lj0W4rCrMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/cases-histories/[id].vue?macro=true";
import { default as _91id_93dyPDl4rdc5Meta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/cases/detail/[id].vue?macro=true";
import { default as indexymhzpAGuXiMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/cases/index.vue?macro=true";
import { default as index268rylE9arMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/estate-agents-champion-leagues/index.vue?macro=true";
import { default as faqsjwQ14mqedOMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/faqs.vue?macro=true";
import { default as forgot_45passwordzyoSTkWZijMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/forgot-password.vue?macro=true";
import { default as confirm0HwHlt2YrcMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/gluedog/confirm.vue?macro=true";
import { default as finalise_45authUwl8iIXyOQMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/gluedog/finalise-auth.vue?macro=true";
import { default as identification_45checks9E2E9SSmkJMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/identification-checks.vue?macro=true";
import { default as income_45statsck6fJsEeNOMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/income-stats.vue?macro=true";
import { default as indexjObYtnfDicMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/index.vue?macro=true";
import { default as loginuGDYdhk1hQMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/login.vue?macro=true";
import { default as indexUo757AYPupMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/material-information-packs/index.vue?macro=true";
import { default as negotiators_45board2UK53IXKe2Meta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/negotiators-board.vue?macro=true";
import { default as negotiators_45leagueSIAkbx0GBHMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/negotiators-league.vue?macro=true";
import { default as quote_45appGf0iSoQ1TLMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/quote-app.vue?macro=true";
import { default as quote_45casesTVrsWClHyJMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/quote-cases.vue?macro=true";
import { default as create8inCjYfEkLMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/quote-requests/create.vue?macro=true";
import { default as indexAXSHjiBQWOMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/quote-requests/index.vue?macro=true";
import { default as _91token_93KdZU1Y8wKCMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/reset-password/[token].vue?macro=true";
import { default as indexZhRe64RocmMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/service-orders/aml/index.vue?macro=true";
import { default as video_45guidesSvI0ocXDCaMeta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/video-guides.vue?macro=true";
import { default as component_45stubTQyQfAXZJ4Meta } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubTQyQfAXZJ4 } from "/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account",
    path: "/account",
    meta: indexdRokSwOrpoMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/account/index.vue")
  },
  {
    name: "account-manage",
    path: "/account/manage",
    meta: manageYMlnSb7RlLMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/account/manage.vue")
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: securityLIvReDfQ4kMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/account/security.vue")
  },
  {
    name: "aml-check-buyer-create",
    path: "/aml-check/buyer/create",
    meta: createM07o6y4v5eMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/aml-check/buyer/create.vue")
  },
  {
    name: "aml-check-buyer",
    path: "/aml-check/buyer",
    meta: index8hU88Q7le0Meta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/aml-check/buyer/index.vue")
  },
  {
    name: "aml-check-seller-create",
    path: "/aml-check/seller/create",
    meta: createpJDfbvD67YMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/aml-check/seller/create.vue")
  },
  {
    name: "aml-check-seller",
    path: "/aml-check/seller",
    meta: indexmqp6Sv48xKMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/aml-check/seller/index.vue")
  },
  {
    name: "cases-histories-id",
    path: "/cases-histories/:id()",
    meta: _91id_9376Lj0W4rCrMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/cases-histories/[id].vue")
  },
  {
    name: "cases-detail-id",
    path: "/cases/detail/:id()",
    meta: _91id_93dyPDl4rdc5Meta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/cases/detail/[id].vue")
  },
  {
    name: "cases",
    path: "/cases",
    meta: indexymhzpAGuXiMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/cases/index.vue")
  },
  {
    name: "estate-agents-champion-leagues",
    path: "/estate-agents-champion-leagues",
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/estate-agents-champion-leagues/index.vue")
  },
  {
    name: "faqs",
    path: "/faqs",
    meta: faqsjwQ14mqedOMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/faqs.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordzyoSTkWZijMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/forgot-password.vue")
  },
  {
    name: "gluedog-confirm",
    path: "/gluedog/confirm",
    meta: confirm0HwHlt2YrcMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/gluedog/confirm.vue")
  },
  {
    name: "gluedog-finalise-auth",
    path: "/gluedog/finalise-auth",
    meta: finalise_45authUwl8iIXyOQMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/gluedog/finalise-auth.vue")
  },
  {
    name: "identification-checks",
    path: "/identification-checks",
    meta: identification_45checks9E2E9SSmkJMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/identification-checks.vue")
  },
  {
    name: "income-stats",
    path: "/income-stats",
    meta: income_45statsck6fJsEeNOMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/income-stats.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexjObYtnfDicMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginuGDYdhk1hQMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/login.vue")
  },
  {
    name: "material-information-packs",
    path: "/material-information-packs",
    meta: indexUo757AYPupMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/material-information-packs/index.vue")
  },
  {
    name: "negotiators-board",
    path: "/negotiators-board",
    meta: negotiators_45board2UK53IXKe2Meta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/negotiators-board.vue")
  },
  {
    name: "negotiators-league",
    path: "/negotiators-league",
    meta: negotiators_45leagueSIAkbx0GBHMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/negotiators-league.vue")
  },
  {
    name: "quote-app",
    path: "/quote-app",
    meta: quote_45appGf0iSoQ1TLMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/quote-app.vue")
  },
  {
    name: "quote-cases",
    path: "/quote-cases",
    meta: quote_45casesTVrsWClHyJMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/quote-cases.vue")
  },
  {
    name: "quote-requests-create",
    path: "/quote-requests/create",
    meta: create8inCjYfEkLMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/quote-requests/create.vue")
  },
  {
    name: "quote-requests",
    path: "/quote-requests",
    meta: indexAXSHjiBQWOMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/quote-requests/index.vue")
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    meta: _91token_93KdZU1Y8wKCMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/reset-password/[token].vue")
  },
  {
    name: "service-orders-aml",
    path: "/service-orders/aml",
    meta: indexZhRe64RocmMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/service-orders/aml/index.vue")
  },
  {
    name: "video-guides",
    path: "/video-guides",
    meta: video_45guidesSvI0ocXDCaMeta || {},
    component: () => import("/home/testingqadeploy5/AVRilloAgentsWebapp-testing-qa-deploy-5.avrillo-testing.co.uk/pages/video-guides.vue")
  },
  {
    name: component_45stubTQyQfAXZJ4Meta?.name,
    path: "/request-quote",
    component: component_45stubTQyQfAXZJ4
  },
  {
    name: component_45stubTQyQfAXZJ4Meta?.name,
    path: "/request-quotes",
    component: component_45stubTQyQfAXZJ4
  },
  {
    name: component_45stubTQyQfAXZJ4Meta?.name,
    path: "/stats-summary",
    component: component_45stubTQyQfAXZJ4
  },
  {
    name: component_45stubTQyQfAXZJ4Meta?.name,
    path: "/material-information-packs/listing",
    component: component_45stubTQyQfAXZJ4
  },
  {
    name: component_45stubTQyQfAXZJ4Meta?.name,
    path: "/material-information-packs/listing-with-aml",
    component: component_45stubTQyQfAXZJ4
  },
  {
    name: component_45stubTQyQfAXZJ4Meta?.name,
    path: "/material-information-packs/valuation",
    component: component_45stubTQyQfAXZJ4
  }
]